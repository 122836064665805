D3R.carousels = [];

D3R.carousel = function() {
    document.querySelectorAll('.js-carousel').forEach((el) => {
        const carousel = new D3RSite.Carousel(el);
        D3R.carousels.push(carousel);

        window.addEventListener('load', () => {
            carousel.init();
        })
    });
}
